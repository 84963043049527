import * as React from "react"
import Layout from '../components/Layout/Layout';
import styled from '@emotion/styled'
import { graphql, Link } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import { Breakpoints, blissfulColour, blissfulColourDark, blissfulColourLight } from '../globalStyles';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export const queryMobile = graphql`
  query {
    sliderImages: allFile(
      filter: {
        sourceInstanceName: {eq: "images"}, 
        relativeDirectory: {eq: "slider"}
      },
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          id
          name
          relativePath
          relativeDirectory
          publicURL
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              layout: FULL_WIDTH
              quality: 90
              formats: WEBP
            )
          }
        }
      }
    }
  }
`

const ImageSliderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: inline-block;

  .gatsby-image-wrapper {
    height: 100%;
  }

  img {
    object-fit: cover !important;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
`

const Container = styled.div`
  margin: 0 auto;
  margin-top: -30px;
  margin-bottom: 30px;
  max-width: 960px;
  padding: 0 20px;
`

const TitleHeader = styled.h1`
  text-align: center;
  color: #ee9fa0;
  margin: 0 20px 20px 30px;
  font-family: 'Great Vibes';
`

const ButtonContainer = styled.div`
  text-align: center;
  margin: 20px;
`

const Button = styled.button`
  padding: 15px 25px; 
  color: white;
  background-color: ${blissfulColour};
  border: none;
  text-align: center;
  border-radius: 15px;

  :hover {
    cursor: pointer; 
    filter: brightness(120%);
  }
`

const DrawingSection = styled.div`
  max-width: 1200px;
  margin: auto;
  margin-top: 40px;
`

const DrawingItemContainer = styled.div`
  display: flex;
`

const DrawingPicture = styled.div`
  width: 50%;
`

const DrawingTextArea = styled.div`
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const DrawingText = styled.h2`
  margin: 0 15px;
  font-size: 1rem;
  @media screen and (min-width: ${Breakpoints.Mobile}) {
    font-size: 1.5rem;
  }
`

const LinkContainer = styled.div`
  text-align: center;
  margin: 20px;

  a {
    color: ${blissfulColour};
  }
  a:visited {
    color: ${blissfulColourDark};
  }
  a:hover {
    color: ${blissfulColourLight};
  }  
`

const HomePage = (data) => {
  let pathname = typeof window !== "undefined" ? window.location.pathname : ""
  return (
    <>
      <Layout
        title="Custom Wedding Cakes - Serving Toronto & GTA"
        description="Custom designed and beautifully crafted cakes for all occasions in Toronto and the GTA. Delivery or Pickup options available."
        pathname={pathname}
      >   
        <Carousel
          emulateTouch={true}
          showStatus={false}
          dynamicHeight={false}
          autoPlay={true}
          infiniteLoop={true}
          centerMode={true}
          centerSlidePercentage={100}
          interval={3500}
          transitionTime={1000}
          stopOnHover={true}
          useKeyboardArrows={true}
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={30}
        >
          { data.data.sliderImages.edges.map(image => (
            <ImageSliderContainer key={image.node.id}>
              <GatsbyImage
                image={image.node.childImageSharp.gatsbyImageData}
                alt={image.node.name}
                objectFit="cover"
              />
            </ImageSliderContainer>
          )) }
        </Carousel>

        <Container>
          <TitleHeader>
            Toronto's Most Beautiful Wedding Cakes
          </TitleHeader>

          <p>
            Blissful Crumbs creates custom cakes cupcakes for all occasions and delivers throughout all of the GTA. 
            All of our cakes are custom made to your specifications using only the freshest and high quality ingredients. 
            With our wide assortment of flavours, we know that our cakes will not only look amazing, but they will taste even better! <Link to="/flavours">See Flavours </Link>
          </p>

          <p>
            Enjoy looking through our website gallery for inspiration. 
            We can help you chose a style and size of cake that will suit the number of guests and venue. 
            We look forward to working with you for your big day! 
            Please feel welcome to contact us if you have any further questions.
          </p>

          <ButtonContainer>
            <Link to="/contact">
              <Button>
                Contact Us
              </Button>
            </Link>
          </ButtonContainer>

        </Container>

        <DrawingSection>
          <DrawingItemContainer>
            <DrawingPicture>
              <StaticImage
                src="../images/toronto-wedding-cake-drawing.jpeg"
                alt="drawing of wedding cake sketch in progress with hand and pen visible"
                quality={90}
              >
              </StaticImage>

            </DrawingPicture>
            <DrawingTextArea>
              <DrawingText>
                If you can’t find a cake that’s perfect for you in our gallery,...
                <LinkContainer>
                  <Link to="/gallery">
                    View Gallery
                  </Link>
                </LinkContainer>
              </DrawingText>


            </DrawingTextArea>
            </DrawingItemContainer>

            <DrawingItemContainer>
              <DrawingTextArea>
                <DrawingText>
                  ...we can customize a very unique and beautiful design just for you!
                  <LinkContainer>
                    <Link to="/contact">
                      Contact Us
                    </Link>
                  </LinkContainer>
                </DrawingText>
              </DrawingTextArea>
              <DrawingPicture>
                <StaticImage
                  src="../images/toronto-wedding-cake-sketch.jpeg"
                  alt="two wedding cake paper sketches"
                  quality={90}
                >
                </StaticImage>

              </DrawingPicture>
            </DrawingItemContainer>
          </DrawingSection>

      </Layout>
    </>
  )
}

export default HomePage

